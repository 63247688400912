.map-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: rgb(245, 241, 241);
    margin-top: 0.5rem;
    width: 100%;
}

.map-image{
    margin-top: 0.5rem;
    width: 60%;
    height: 35vmax;
}

.map-image >img {
    width: 100%;
    height: 100%;
}


@media(max-width:600px){

    .map-image{
        width: 100%;
        height: 45vmax;
    }
}

@media only screen and (min-width:600px) and (max-width:900px){

    .map-image{
        height: 55vmax;
        width: 100%;
    }
}
.hero-container{
   height: 500px;
   margin-top: 6rem;  
   width: 100%;
}

.hero-container > img {
    width: 100%;
    height: 100%;
}

@media (max-width: 600px)
{
    .hero-container{
        margin-top: 6rem;
    }
}

@media only screen and (min-width:600px) and (max-width:900px) {

    .hero-container{
        margin-top: 10rem;
    }
}

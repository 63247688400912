@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');


.contact-container{
    margin-top: 0.5rem;
    display: flex;
    padding: 3.5rem;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.contactDetail-container{
    padding: 2rem;
}
.address-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.address-container span{
    font-weight: 800;
    margin: 0.8rem;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.3rem;
}

.address-container p{
    font-weight: 300;
    margin: 0.8rem;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.3rem;
}

@media screen and (min-width:600px) and (max-width:900px){
 
    .address-container{
        flex-direction: column;
    }
    .address-container span{
        font-size: 2.5rem;
        text-align: center;
    }
    .address-container p{
        font-size: 2.5rem;
        text-align: center;
    }
   
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');


.title{
    font-size: 2rem;
    border-left: 5px solid black;
    padding-left: 1.5rem;
    font-family: 'Playfair Display', serif;
    margin: 1rem;
    text-align: center;
    color: rgb(37, 37, 152);
   display: flex;
   flex-wrap: wrap;
} 
.footer-container{
    background-image: linear-gradient(#464d55, #25292e);
    width: 100%;
    height: 6vmax;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px){

    .footer-container{
        height: 21vmax;
    }

}

@media only screen and (min-width:600px) and (max-width:900px){
    .footer-container{
        height: 21vh;
    }
}
.floor-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: rgb(245, 241, 241);
    margin-top: 0.5rem;
    width: 100%;
    /* height: 40vmax; */
}

.floor-images{
    display: inline-grid;
    grid-template-columns:auto auto auto;
    justify-content: center;
    align-items: center;
    row-gap: 1.6rem;
    column-gap: 4rem;
    width: 100%;
}

.floor-images>button{
  border: 0px;
}

.image-container>img{
    border: 20px solid white;
    width: 20rem;
    height: 20rem;
}

.image-container-1{
    width: 100%;
    display:flex ;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 3rem;
}

.image-container-1-1{
    width: 40%;
    margin: 2.4rem;
}

.image-container-1-1>img{
    width: 40rem;
    border: 20px solid white;
}

.image-container-1-2{
    width: 40%;
    margin: 2.4rem;
}

.image-container-1-2>img{
    width: 40rem;
    border: 20px solid white;
}

@media (max-width:600px)
{

    .floor-images{
        display: flex;
        flex-direction: column;
    }


    .image-container-1{
        margin-left: 0px;
        flex-direction: column;
        justify-content: center;
    }

    .image-container-1-1{
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .image-container-1-2{
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .image-container-1-1>img{
        width: 20rem;
    }

    .image-container-1-2>img{
        width: 20rem;
    }

}

@media (max-width:900px){

    .floor-images{
     display: flex;
     flex-wrap: wrap;
    }

    .image-container:nth-last-child(1):nth-child(odd){
            grid-column: 2 / span 1;
    }
    .image-container-1{
       flex-direction: column;
       margin-left: 0%;
    }
}